import {
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import Accordion from "../Accordion";
import Title from "../Line";
import "./style.css";
import { getStaticURL } from "../../constants";
const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingTop: "auto",
    paddingBottom: "auto",
  },
  muiAccordionRoot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
      height: 0,
      display: "none",
    },
  },
  accordContent: {
    margin: "0 !important",
  },
  expanded: {
    minHeight: "auto !important",
  },
}));

const OurService = ({ ourServiceSection }) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const title = "Our Services";
  const classes = useStyles();
  const subtitle = "";
  return (
    <div ref={ourServiceSection} className="md:pt-5 pb-10 mx-auto container">
      <div>
        <div>
          <div className="w-full">
            <Title title={title} />
            <Accordion
              className="pb-10"
              elevation={0}
              classes={{
                root: classes.muiAccordionRoot,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "57px" : "0px"}`,
                }}
                elevation={0}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                {!isMobile ? (
                  <img src={`${getStaticURL()}/assets/images/SERVICE_ICON_1.png`} alt="service_1" className="md:w-12" />
                ) : null}
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-8 items-center flex text-[16px] md:text-[18px] text-mainHeader">
                    Diamond Grading Report
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column", textAlign: "left" }}
              >
                <p className="pt-5 text-mainText md:text-[18px]">
                  Full 4Cs assessment with a plotted Carat, Colour, Clarity, Cut
                  quality grading. Results delivered on the report. Variety of
                  testing services to determine diamond authenticity, natural
                  vs. synthetic, natural vs. treated, or identify specific
                  treatments.
                </p>
                <p className="pt-5 text-mainText md:text-[18px]">
                  We provide full quality assessment for colour grade and
                  clarity plot as well. Investigations are routinely performed
                  on diamonds in order to determine whether a diamond is of
                  natural or artificial colour via observation with a microscope
                  in a dark field, transmitted light, polarized light, and
                  immersion.
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion
              classes={{
                root: classes.muiAccordionRoot,
              }}
              className="pb-10"
              elevation={0}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "50px" : "0px"}`,
                }}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                {!isMobile ? (
                  <img
                    src={`${getStaticURL()}/assets/images/SERVICE_ICON_2.png`}
                    className="h-[50px]"
                    alt="service_1"
                  />
                ) : null}
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-9 items-center flex text-mainHeader text-[16px] md:text-[18px]">
                    Colourstone Identification Report
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column", textAlign: "left" }}
              >
                <p className="pt-5 text-mainText md:text-[18px]">
                  Colour stones reports are issued for individual loose
                  gemstones. We will provide a descriptive identity of gemstone
                  examination, such as "Natural" for the natural formation of
                  stone,"Synthetic" for synthetic crystals and "Treated" for
                  stones that underwent rigorous treatment. The Colour of stones
                  will also be identified in the report such as the description
                  of the spectral hue on the gemstone(Saturation, Tone and
                  Type). The report will also provide descriptive details of the
                  weight, dimensions, shape, cutting style, transparency and
                  origin.
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion
              classes={{
                root: classes.muiAccordionRoot,
              }}
              className="pb-10"
              elevation={0}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "50px" : "0px"}`,
                }}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                {!isMobile ? (
                  <img
                    src={`${getStaticURL()}/assets/images/SERVICE_ICON_3.png`}
                    className="h-[50px]"
                    alt="service_1"
                  />
                ) : null}
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-9 items-center flex text-mainHeader text-[16px] md:text-[18px]">
                    Origin Profile Matching
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column", textAlign: "left" }}
              >
                <p className="pt-5 text-mainText md:text-[18px]">
                  Gems from particular geographical locations have distinctive
                  features. High-quality rubies from Burma, for example, tend to
                  display a vivid fluorescent red while sapphires from Kashmir
                  show a deep blue with a velvety softness. Identifying the
                  origin of gemstones requires going beyond surface appearance
                  to the microscopic structure of the material, and observing
                  the telltale signs of crystal formation that are distinctive
                  of the specific location. This is a process that requires
                  industry experts and professional equipment.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: classes.muiAccordionRoot,
              }}
              className="pb-10"
              elevation={0}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "56px" : "0px"}`,
                }}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                {!isMobile ? (
                  <img
                  src={`${getStaticURL()}/assets/images/SERVICE_ICON_4.png`}
                    className="h-[50px]"
                    alt="service_1"
                  />
                ) : null}
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-[42px] items-center flex text-mainHeader text-[16px] md:text-[18px]">
                    Expert Witnesses
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column", textAlign: "left" }}
              >
                <p className="pt-5 text-mainText md:text-[18px]">
                  Gemology is the art and science of studying, cutting, valuing,
                  buying, and selling precious stones. Gemologists work with
                  various precious stones such as diamonds, amethyst, opals,
                  rubies, sapphires, and emeralds. Our gemology expert witnesses
                  have years of experience in the field and are practising
                  jewellers.
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion
              classes={{
                root: classes.muiAccordionRoot,
                content: classes.accordContent,
                expanded: classes.expanded,
              }}
              className="pb-10"
              elevation={0}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "50px" : "0px"}`,
                }}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                {!isMobile ? (
                  <img
                  src={`${getStaticURL()}/assets/images/SERVICE_ICON_5.png`}
                    className="h-[50px]"
                    alt="service_1"
                  />
                ) : null}
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-9 items-center flex text-mainHeader text-[16px] md:text-[18px]">
                    Valuation appraisal
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column", textAlign: "left" }}
              >
                <p className="pt-5 text-mainText md:text-[18px]">
                  Professional valuation done by SGA is fully researched and is
                  wholly justifiable. Enquiries and research for market data are
                  made for current gemstone, precious metals, and manufacturing
                  costs. Data through major jewellery makers, and auction houses
                  will also be taken into consideration. In this way, direct
                  comparisons can be made with similar assets in the appropriate
                  markets at the time of valuation and are therefore less
                  susceptible to being challenged.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: classes.muiAccordionRoot,
                content: classes.accordContent,
                expanded: classes.expanded,
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "62px" : "0px"}`,
                }}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                {!isMobile ? (
                  <img
                  src={`${getStaticURL()}/assets/images/SERVICE_ICON_6.png`}
                    className="h-[50px]"
                    alt="service_1"
                  />
                ) : null}
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-11 items-center flex text-center text-mainHeader text-[16px] md:text-[18px]">
                    Watch Authentication
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column", textAlign: "left" }}
              >
                <p className="pt-5 text-mainText md:text-[18px]">
                  With the tremendous growth in digitalisation and eCommerce,
                  the purchase of luxury timepieces over the internet can be
                  carried out with much ease and convenience. In the pre-owned
                  luxury watch market, there are numerous watches transacted
                  between private dealers. Most of the time, these watches are
                  not authenticated by a professional and you face the risks of
                  purchasing counterfeit. The ease and convenience of peer to
                  peer luxury watch transactions has led to a proliferation of
                  replicas and outright fakes being spawned off as authentic,
                  leading to substantial losses to buyers as a result. Over the
                  years, high-grade counterfeits are becoming a challenge to
                  authenticate without the right equipment and know-hows as
                  well.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <Title title={subtitle} />
        <div className="text-left text-mainText text-[16px] md:text-[18px]">
          <p className="mb-5">
            SGA ensures the authenticity of your newly acquired luxury watch in
            addition to taking the trouble, time and effort to personally travel
            to the source and personally inspect the watch. Our specialists have
            decades of experience in the luxury wristwatch industry, equipped
            with valuable knowledge of differentiating an authentic luxury watch
            from a counterfeit.
          </p>
          <p className="md:mb-5">
            SGA watch authentication service offers reassurance that a watch is
            exactly what it purports to be, prior to its valuation or at the
            point of sale. We offer authentication services for most of the
            prestige luxury watch brands such as Rolex, Patek Philippe, Audemars
            Piguet, Hublot, Jaeger-Lecoultre, Tudor, Cartier, Chopard, Piaget,
            IWC, Lange, Richard Mille, Omega and Panerai.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurService;
