import React from "react";
import Title from "../Line";
import { getStaticURL } from "../../constants";
const SGAValues = ({ SGAValuesSection }) => {
  const title = "SGA value";
  return (
    <div
      ref={SGAValuesSection}
      className="pt-5 md:pt-10 bg-white pb-5 container md:pb-10 mx-auto"
    >
      <Title title={title} />
      <div className="flex justify-center mx-auto items-center pb-5 md:pb-10">
        <div className="mx-auto md:w-9/12">
          <div className="mx-auto">
            <video src={`${getStaticURL()}/assets/images/SGA_VALUE.mp4`} autoPlay muted loop />
          </div>
        </div>
      </div>
      <div className="text-left text-mainText text-[16px] md:text-[18px]">
        <p>
          Every individual or company is unique. A one-size-fits-all approach
          will never help you or your business reach its full potential. We
          deliver customized solutions, tailored for you – your industry, your
          clients, your culture, your one-of-a-kind challenges. Whether you're
          looking for certification or complete advance testing, SGA has you in
          good hands.
        </p>
      </div>
    </div>
  );
};

export default SGAValues;
