import {
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import Accordion from "../Accordion";
import Title from "../Line";
import "./style.css";
import { getStaticURL } from "../../constants";


const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingTop: "auto",
    paddingBottom: "auto",
  },
  muiAccordionRoot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
      height: 0,
      display: "none",
    },
  },
  accordContent: {
    margin: "0 !important",
  },
  expanded: {
    minHeight: "auto !important",
  },
}));

const QASection = ({ qaSection }) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const title = "Q & A";
  const classes = useStyles();
  return (
    <div ref={qaSection} className="md:pt-5 pb-10 mt-10 mx-auto container">
      <div>
        <div>
          <div className="w-full">
            <Title title={title} />
            <Accordion
              className="pb-10"
              elevation={0}
              classes={{
                root: classes.muiAccordionRoot,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "57px" : "0px"}`,
                }}
                elevation={0}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-8 items-center flex text-[16px] md:text-[18px] text-mainHeader">
                    Q: Who are you? What&apos;s your profession? Where is your
                    business located at?
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  flexDirection: "column",
                  textAlign: "left",
                  padding: "10px 0",
                }}
              >
                <div className="qa-detail">
                  <img
                    alt="walter_tan_img"
                    src={`${getStaticURL()}/assets/images/wt_4.jpg`}
                    className="answerImg--vertical"
                  />
                  <div className="flex flex-col qa-paragraph">
                    <p className="text-mainText md:text-[18px]">
                      My name is Walter Tan, I am a Gemology Consultant,
                      Ex-Inland Revenue Licensed Valuer and appointed appraiser,
                      expert witnesses to High Court and Sub-Court of Singapore
                      classroom training, knowledge development programmes and
                      valuation for insurance companies, corporates.
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      Daily we do identification of gemstone, grading of diamond
                      and valuation for jewellery. We do on-site authentication
                      at Security warehouse, law firm and banks, sometimes even
                      travel overseas to do appraisal exercise!
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      My business address is at 14 Scott&apos;s Road #03-03 Far
                      East Plaza, 228213.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="pb-10"
              elevation={0}
              classes={{
                root: classes.muiAccordionRoot,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "57px" : "0px"}`,
                }}
                elevation={0}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-8 items-center flex text-[16px] md:text-[18px] text-mainHeader">
                    Q: How did you start this business? (What inspired you?)
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  flexDirection: "column",
                  textAlign: "left",
                  padding: "10px 0",
                }}
              >
                <div className="qa-detail">
                  <img
                    alt="walter_tan_img"
                    src={`${getStaticURL()}/assets/images/wt_2.jpg`}
                    className="answerImg--horizontal"
                  />
                  <div className="flex flex-col qa-paragraph">
                    <p className="text-mainText md:text-[18px]">
                      With a group of friends, we have the same vision that the
                      jewellery community still have a wide gap to fill in. We
                      felt we could serve better so we came together to start
                      off this business.
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      Our goal and dream is to put SGA to be a leading
                      gemological lab in South East Asia and beyond the region!
                      Is it our passion and love of gemstones give us the drive
                      to develop this business, and it is huge market in South
                      East Asia.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="pb-10"
              elevation={0}
              classes={{
                root: classes.muiAccordionRoot,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "57px" : "0px"}`,
                }}
                elevation={0}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-8 items-center flex text-[16px] md:text-[18px] text-mainHeader">
                    Q: How long have you been in this business venture,
                    experiences?
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  flexDirection: "column",
                  textAlign: "left",
                  padding: "10px 0",
                }}
              >
                <div className="qa-detail">
                  <img
                    alt="walter_tan_img"
                    src={`${getStaticURL()}/assets/images/wt_6.jpg`}
                    className="answerImg--vertical-extended"
                  />
                  <div className="flex flex-col qa-paragraph">
                    <p className="text-mainText md:text-[18px]">
                      I have been in this trade after my national service and
                      all along working in the gem lab. Beside doing the gem
                      testing, I also do training for the jewellery retail in
                      gemstone and diamond knowledge, retail giants such as
                      Aspial (Leehwa) jewellery, Sookee jewellery and Meyson
                      jewellery etc.
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      I completed my Valuation Program with Anna Miller(USA). A
                      little background of Anna Miller, Graduate Gemologist,
                      gems and jewelry appraisal authority and author, and
                      developer/international director of The Master Valuer
                      Program. Her involvement in the industry included being a
                      Senior Member of the American Society of Appraisers, a
                      past Regional Governor of the Accredited Gemologist
                      Association, co-founder and International Director of the
                      Association of Women Gemologists, a Senior Member of the
                      National Association of Jewelry Appraisers, and a Master
                      Gemologist Appraiser.
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      Other professional affiliations include membership in
                      AGTA, GIA Alumni Association, AGA, American Society of
                      Jewelry Historians, the International Society of
                      Appraisers, and the Society of Jewellery Historians, in
                      both the United States and Great Britain.
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      One year later I got my valuation license from IRAS. Since
                      than I have been actively involved in valuation cases for
                      estates, divorced and insurance. I was also appointed by
                      the high-court and sub-court for jewellery valuation
                      matters.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="pb-10"
              elevation={0}
              classes={{
                root: classes.muiAccordionRoot,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "57px" : "0px"}`,
                }}
                elevation={0}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-8 items-center flex text-[16px] md:text-[18px] text-mainHeader">
                    Q: When and where did you start from?
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  flexDirection: "column",
                  textAlign: "left",
                  padding: "10px 0",
                }}
              >
                <div className="qa-detail">
                  <img
                    alt="walter_tan_img"
                    src={`${getStaticURL()}/assets/images/wt_5.jpg`}
                    className="answerImg--horizontal"
                  />
                  <div className="flex flex-col qa-paragraph">
                    <p className="text-mainText md:text-[18px]">
                      Since young I have been following my parents to the market
                      to see how they sell jade. As I grew older, I have
                      developed the interest in colour stones because of all
                      their fascinating colour.
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      After my national service I had an opportunity to work in
                      one of the leading gem lab at that time. At the same time,
                      I did my GIA(USA) graduate gemologist program and later
                      HRD(Belgium) graduate gemologist program. This is all how
                      it started, it is our love and passion for the industry
                      that keeps me going, inspiring me to learn more and more
                      about the industry. We are also looking to further excel
                      ourselves and more breakthroughs in the industry.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="pb-10"
              elevation={0}
              classes={{
                root: classes.muiAccordionRoot,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "57px" : "0px"}`,
                }}
                elevation={0}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-8 items-center flex text-[16px] md:text-[18px] text-mainHeader">
                    Q: Was it tough? What were some of the obstacles that you
                    faced?
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  flexDirection: "column",
                  textAlign: "left",
                  padding: "10px 0",
                }}
              >
                <div className="qa-detail">
                  <img
                    alt="walter_tan_img"
                    src={`${getStaticURL()}/assets/images/wt_1.jpg`}
                    className="answerImg--horizontal"
                  />
                  <div className="flex flex-col qa-paragraph">
                    <p className="text-mainText md:text-[18px]">
                      As man-made and lab grown gemstone technology evolved,
                      standard gemological equipment&apos;s is not be able to
                      detect the new material. Application to identify the new
                      subject is getting more complex, since than we have
                      invested a lot on the state of art equipment for checking
                      the gemstone and diamond
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="pb-10"
              elevation={0}
              classes={{
                root: classes.muiAccordionRoot,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#F3A351",
                      transform: "scale(2)",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#FFF0E1",
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: `${!isMobile ? "57px" : "0px"}`,
                }}
                elevation={0}
                classes={{
                  root: classes.accordionRoot,
                  content: classes.accordContent,
                  expanded: classes.expanded,
                }}
              >
                <div className="items-center md:justify-start justify-center w-full flex text-center md:text-left">
                  <p className="md:pl-8 items-center flex text-[16px] md:text-[18px] text-mainHeader">
                    Q: What kind of new experiences did this business give you
                    exposure to?
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  flexDirection: "column",
                  textAlign: "left",
                  padding: "10px 0",
                }}
              >
                <div className="qa-detail">
                  <img
                    alt="walter_tan_img"
                    src={`${getStaticURL()}/assets/images/wt_4.jpg`}
                    className="answerImg--vertical"
                  />
                  <div className="flex flex-col qa-paragraph">
                    <p className="text-mainText md:text-[18px]">
                      These new experiences brought me to places where
                      “gemstones” are only widely traded in their particular
                      province.
                    </p>
                    <p className="text-mainText md:text-[18px]">
                      One example that I would like to share is that many years
                      ago, I had the opportunity to work with a group of
                      auditors doing valuation exercise in Fuzhou to value
                      ShouShanShi. It is a valuable experience that is
                      impossible to learn from the book. It is not only an
                      eye-opening experience, but it is also full of challenges.
                      This reminds me to put my feet on the ground because it is
                      a never ending learning journey.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QASection;
