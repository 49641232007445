import React from "react";

import Title from "../Line";
import "./style.css";
import { getStaticURL } from "../../constants";
const OurEquipment = ({ ourEquipmentSection }) => {
  const title = "Our Equipment";
  const equipmentList = [
    {
      name: "Microscope",
      id: 1,
    },
    {
      name: "Dichroscope",
      id: 2,
    },
    {
      name: "Refractive Index",
      id: 3,
    },
    {
      name: "Hydrostatic Balance",
      id: 4,
    },
    {
      name: "Polariscope",
      id: 5,
    },
    {
      name: "Mettler Toledo Auto-Calibration Carat Balances",
      id: 6,
    },
  ];
  return (
    <div className="pt-5 md:pt-10 bg-backgroundGray" ref={ourEquipmentSection}>
      <Title title={title} />
      <div className="md:flex">
        <div className="md:w-6/12 justify-center items-center w-full px-4 md:px-0 pb-5 md:pb-0 md:pr-5">
          <img
            src={`${getStaticURL()}/assets/images/OUR_EQUIPMENT.png`}
            alt="ourequipment-banner"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="md:w-6/12">
          <div className="text-left px-4 md:px-2 text-[16px] lg:pr-[80px] text-mainText md:text-[18px]">
            <p className="md:px-2 lg:px-0">
              At SGA, we utilize the latest technology and equipment in our
              laboratory to analyse and authenticate gemstones. Gemology
              involves studying the optical and physical properties that make
              gems unique and precious. Gem identification involves analyzing
              these properties to differentiate one species from another. There
              are a number of gemology tools used to distinguish or measure
              these properties. Other than using traditional analytical
              techniques, SGA is well equipped with specialized tools for us to
              perform advanced analytical methods. Some of our equipment
              includes-
            </p>
          </div>
          <div className="parent lg:pr-[80px]">
            {equipmentList.map((list) => (
              <div key={list.id} className="child text-[16px] md:text-[18px]">
                <div className="flex px-4 md:px-2 md:mb-5 xl:pt-7 pt-5 pb-5">
                  <img
                    src={`${getStaticURL()}/assets/images/ORANGE_DIAMOND.png`}
                    alt="diamond"
                    className="h-[10px] object-cover w-[14px] xl:h-[15px] xl:w-[20px] lg:w-[17px] mt-2 lg:h-[12px] xl:mt-1.5 md:absolute"
                  />
                  <ul>
                    <li className="text-left px-5 xl:ml-2 md:mb-0 xl:whitespace-nowrap text-mainHeader">
                      {list.name}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurEquipment;
