import React from "react";
import Title from "../Line";
import { getStaticURL } from "../../constants";
const AboutUs = ({ aboutUsSection }) => {
  const title = "About us";
  return (
    <div
      ref={aboutUsSection}
      className="md:pb-10 pt-5 md:pt-10 mx-auto container"
    >
      <Title title={title} />
      <div className="bg-white justify-center">
        <div className="md:flex">
          <div className="md:w-6/12 justify-center flex items-center">
            <div className="w-full justify-center px-4 pb-5 md:pb-0">
              <img src={`${getStaticURL()}/assets/images/SGA_aboutus.jpg`} className="object-cover w-full" alt="clock" />
            </div>
          </div>
          <div className="md:w-6/12 md:text-[18px] pb-10 md:pb-0 text-left text-[16px] text-mainText">
            <p className="mb-5">
              As the industry leader, SGA is an independent gemological
              certificates provider for diamonds, gemstones and luxury watches.
              The only way to meet the challenges that arise in the current
              market is to operate in the same way as large international
              laboratories. This is why SGA uses industry-leading protocols and
              the best equipment with no compromises.
            </p>
            <p>
              To ensure the reliability and the accuracy of our results to our
              clients, it is necessary that the result is based on the analysis
              of experienced professionals. This is why we uphold a stringent
              approach and SOP for all of our in-house specialists. We strongly
              believe that research is essential to maintain a high level of
              quality, so we are constantly working to improve our processes,
              equipment and databases and working closely with industry experts
              allows us to achieve this.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
