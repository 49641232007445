import { withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";

const StyledAccordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export default StyledAccordion;
