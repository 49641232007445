import React from "react";
import Title from "../Line";

const QualitySeal = ({ qualitySealSection }) => {
  const title = "Singapore Gemology Authority";
  return (
    <div
      className="mb-5 md:text-[18px] py-5 bg-backgroundTangerine"
      ref={qualitySealSection}
    >
      <Title title={title} />

      <p className="text-left px-4 text-qualitySealText text-[16px] md:text-[18px] mx-auto container pb-5">
        Ever since its incorporation, Singapore Gemology Authority has been
        pursuing the highest possible quality standards, without any compromise
        with regards to analytical infrastructure, level of education and staff
        know-how, setting strict internal regulations and processes to warrant
        and perpetuate these outstanding quality standards. To set a quality
        hallmark, a set of guidelines must be followed, in order to produce a
        trusted gemological document.
      </p>
    </div>
  );
};

export default QualitySeal;
