import React from "react";
import Title from "../Line";
import { getStaticURL } from "../../constants";

const LuxuryEvaluation = ({ luxuryEvaluationSection }) => {
  const title = "Luxury Watch Valuation";
  return (
    <div
      ref={luxuryEvaluationSection}
      className="pt-5 md:pt-10 md:pb-7 pb-5 bg-backgroundGray"
    >
      <Title title={title} />
      <div className="flex justify-center items-center pb-5">
        <div className="w-full px-4 md:px-0">
          <img src={`${getStaticURL()}/assets/images/LUXURY_EVALUATION.png`} className="object-cover w-full" alt="clock" />
        </div>
      </div>
      <div className="text-left mt-5 px-4 text-[16px] text-mainText mx-auto md:text-[18px] container">
        <p className="mb-5">
          SGA offers luxury watch evaluation services for you to receive the
          most accurate market value for your beloved timepiece. There are key
          parallels in that better condition, documentation, and market demand
          will yield better sale results. Beyond observable market-based
          indicators of market demand, the condition and accessory sets specific
          to your individual watch are key factors in evaluating luxury
          timepieces.
        </p>
        <p>
          Market demand drives the ultimate potential sales value of a watch.
          Other than gathering market data from previous transactions and sales,
          identifying the exact variant, and factors such as bracelet or strap
          status, dial colour, and year of manufacture can cause the value of a
          given unit to rise or fall. Market value fluctuates according to many
          factors including economic, therefore at SGA, we keep up to date with
          all internal and external factors to provide market-driven data.
        </p>
      </div>
    </div>
  );
};

export default LuxuryEvaluation;
