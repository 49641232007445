import React from "react";
import { useMediaQuery } from "@material-ui/core";
import "./style.css";
import { getStaticURL } from "../../constants";
const Header = (props) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const dataLink = [
    {
      title: "About Us",
      id: 1,
      function: props.scrollToAboutUs,
    },
    {
      title: "SGA Quality Seal",
      id: 2,
      function: props.scrollToQualitySeal,
    },
    {
      title: "Our Services",
      id: 3,
      function: props.scrollToOurService,
    },
    {
      title: "Luxury Watch Valuation",
      id: 4,
      function: props.scrollToLuxuryEvaluation,
    },
    {
      title: "SGA Values",
      id: 5,
      function: props.scrollToSGAValues,
    },
    {
      title: "Our Equipment",
      id: 6,
      function: props.scrollToOurEquipment,
    },
    {
      title: "Contact Us",
      id: 7,
      function: props.scrollToFooter,
    },
  ];

  return (
    <div className="header container mx-auto justify-between pt-7 font-opensansbold">
      {!isMobile ? (
        <div className="justify-between flex items-center">
          <div className="justify-start items-center mb-2 md:pt-7 pt-2 md:mb-10">
            <img src={`${getStaticURL()}/assets/images/NEW_SGA_LOGO.png`} alt="sga" className="md:w-[80px] w-[100%]" />
          </div>
          <nav className="text-center flex items-center">
            <div className="flex h-[10px] space-x-5 text-white w-full">
              {dataLink.map((link, index) => (
                <ul
                  key={link.id}
                  className="flex items-center text-center text-[13px] pr-5 capitalize"
                >
                  <li className="capitalize border-b-2 border-transparent border-transition">
                    <div
                      className="cursor-pointer font-bold uppercase"
                      onClick={link.function}
                    >
                      {link.title}
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          </nav>
        </div>
      ) : (
        <div className="flex justify-center items-center ml-auto mr-auto py-5">
          <img src={`${getStaticURL()}/assets/images/NEW_SGA_LOGO.png`} alt="sga" className="w-3/12 md:w-1/12" />
        </div>
      )}
    </div>
  );
};

export default Header;
