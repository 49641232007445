import React from "react";
import "./style.css";
import { getStaticURL } from "../../constants";
const Banner = () => {
  return (
    <div className="bg-backgroundGray">
      <div className="bg-black relative">
        <div className="bg-black video-container">
          <video src={`${getStaticURL()}/assets/images/SGA_BANNER.mp4`} autoPlay muted loop />
        </div>
      </div>
      <div className="container mx-auto px-4">
        <div className="italic mt-10 md:mt-[-40px] relative md:p-7 border-2 border-borderBanner py-9 bg-white justify-center">
          <p className="px-2 md:px-0 text-[18px] font-opensanslight">
            Diamonds and coloured stones take years to form, weaving a rich
            tapestry of untold stories found deep within.
          </p>
          <p className="pb-7 px-2 md:px-0 text-[18px] font-opensanslight">
            Luxury watches are crafted by some of the best watchmakers in the
            world.
          </p>
          <h3 className="md:text-[18px] not-italic text-[16px] text-black px-2 md:px-0">
            Identifying and authentic these luxury assets requires the best
            equipment and experts within the industry.
          </h3>
        </div>
      </div>
      <div className="justify-center w-full text-center ml-auto mr-auto pt-10 md:pt-0 bg-backgroundGray">
        <div className="container px-4 mx-auto md:pt-10 pb-5 md:text-[18px] text-mainText font-opensans text-[16px]">
          <p className="mb-5 text-left ">
            Our group of certified subject matter experts are professionally
            trained to provide an objective and balanced assessment of luxury
            watches, and various gems such as diamonds and coloured stones – to
            reveal their true character. Our professional qualifications equip
            us with technical knowledge, yet our advantage lies in how we
            combine our human analytical skills with technological expertise in
            utilising advanced state-of-the-art equipment. It doesn’t matter if
            you are a gemstone trader, corporate buyer, a hobbyist with a
            passion for gemstones and watches. We have invested in an array of
            the latest technology to deliver accurate testing reports that you
            can truly rely on.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
