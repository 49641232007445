import "./App.css";
import QualitySeal from "./components/QualitySeal";
import OurServices from "./components/OurServices";
import LuxuryEvaluation from "./components/LuxuryEvaluation";
import SGAValues from "./components/SGAValues";
import OurEquipment from "./components/OurEquipment";
import AdvanceEquipment from "./components/AdvanceEquipment";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Banner from "./components/Banner";
import React, { useRef } from "react";
import AboutUs from "./components/AboutUs";
import QASection from "./components/QA";
function App() {
  const aboutUsSection = useRef();
  const qualitySealSection = useRef();
  const ourServiceSection = useRef();
  const luxuryEvaluationSection = useRef();
  const SGAValuesSection = useRef();
  const ourEquipmentSection = useRef();
  const footerSection = useRef();
  const qaSection = useRef();

  const scrollToQualitySeal = () => {
    window.scrollTo({
      top: qualitySealSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const scrollToAboutUs = () => {
    window.scrollTo({
      top: aboutUsSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const scrollToOurService = () => {
    window.scrollTo({
      top: ourServiceSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const scrollToLuxuryEvaluation = () => {
    window.scrollTo({
      top: luxuryEvaluationSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const scrollToSGAValues = () => {
    window.scrollTo({
      top: SGAValuesSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const scrollToOurEquipment = () => {
    window.scrollTo({
      top: ourEquipmentSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const scrollToFooter = () => {
    window.scrollTo({
      top: footerSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="App">
      <div className="bg-black">
        <Header
          scrollToQualitySeal={scrollToQualitySeal}
          scrollToAboutUs={scrollToAboutUs}
          scrollToOurService={scrollToOurService}
          scrollToLuxuryEvaluation={scrollToLuxuryEvaluation}
          scrollToSGAValues={scrollToSGAValues}
          scrollToOurEquipment={scrollToOurEquipment}
          scrollToFooter={scrollToFooter}
        />
      </div>

      <Banner />
      <div className="font-opensans">
        <AboutUs aboutUsSection={aboutUsSection} />
        <QualitySeal qualitySealSection={qualitySealSection} />
        <OurServices ourServiceSection={ourServiceSection} />
        <LuxuryEvaluation luxuryEvaluationSection={luxuryEvaluationSection} />
        <SGAValues SGAValuesSection={SGAValuesSection} />
        <OurEquipment ourEquipmentSection={ourEquipmentSection} />
        <AdvanceEquipment />
        <QASection qaSection={qaSection} />
      </div>

      <Footer footerSection={footerSection} />
    </div>
  );
}

export default App;
