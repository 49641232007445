import React, { useState } from "react";

import "./style.css";

import { TextField, makeStyles, useMediaQuery } from "@material-ui/core";
import Title from "../Line";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    width: "100%",
    ["@media (min-width:768px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "75%",
    },
    marginBottom: "20px",
    display: "flex",
  },
  input: {
    height: 48,
    fontSize: 14,
    color: "#000",
    border: "1px solid #757575",
  },
  error: {
    height: 48,
    fontSize: 14,
    color: "#000",
    border: "1px solid red",
  },
}));

const useStyless = makeStyles((theme) => ({
  inputRootMessage: {
    fontSize: 18,
    borderRadius: 0,
    backgroundColor: "white",
    width: "100%",
    fontFamily: "OpenSans",
    color: "rgb(119,119,119)",
  },
}));

const Footer = ({ footerSection }) => {
  const classes = useStyles();
  const classess = useStyless();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const title = "";

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}/sga/contact-us`,
        {
          method: "POST",
          body: JSON.stringify({
            email: userEmail,
            fullname: userName,
            message: userMessage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (res.status === 201) {
        setUserEmail("");
        setUserName("");
        setUserMessage("");
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="md:text-[18px] bg-black pb-16" ref={footerSection}>
        <div className="container mx-auto">
          <div className="text-center text-[36px] text-white pt-16 font-merryweather">
            Contact us
          </div>
          <div className="flex flex-col">
            <a className="text-[15px] text-white" href="tel:+65 69939068">
              @+65 69939068
            </a>
            <a className="text-[15px] text-white" href="tel:+65 82831353">
              @+65 82831353
            </a>
          </div>
          <Title title={title} />
          <div className="md:px-28">
            <h1 className="text-[18px] text-center text-white">
              Speak with our knowledgeable staff or drop us an email at
              <a href="mailto:admin@sgalab.sg"> admin@sgalab.sg</a>. You will be
              matched with a credible expert for your service.
            </h1>
          </div>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="w-full pt-10 md:flex justify-center pb-5">
              <div className="w-full md:w-6/12 flex justify-center md:justify-end md:pr-1">
                <TextField
                  className={classes.root}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.input,
                  }}
                  inputProps={{
                    type: "text",
                    style: {
                      textAlign: "left",
                      paddingLeft: "10px",
                      fontSize: "18px",
                      color: "rgb(119,119,119)",
                    },
                  }}
                  onChange={(e) => setUserName(e.target.value)}
                  fullWidth
                  required
                  value={userName}
                  name="account"
                  placeholder="Your name"
                />
              </div>
              <div className="w-full md:w-6/12 flex justify-center md:justify-start md:pl-1">
                <TextField
                  className={classes.root}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.input,
                  }}
                  inputProps={{
                    type: "email",
                    style: {
                      textAlign: "left",
                      paddingLeft: "10px",
                      fontSize: "18px",
                      color: "rgb(119,119,119)",
                    },
                  }}
                  required
                  onChange={(e) => setUserEmail(e.target.value)}
                  fullWidth
                  name="email"
                  placeholder="Your email"
                />
              </div>
            </div>
            <div className="w-full md:w-9/12 justify-center ml-auto mr-auto pb-5">
              <TextField
                className={classess.inputRootMessage}
                variant="outlined"
                placeholder="Your Message"
                fullWidth
                value={userMessage}
                multiline
                onChange={(e) => setUserMessage(e.target.value)}
                minRows={isMobile ? 4 : 6}
                InputProps={{
                  className: classess.inputRootMessage,
                }}
              />
            </div>
            <div className="justify-center flex">
              <button
                type="submit"
                className="h-[60px] text-black uppercase w-full md:w-3/12 text-[18px] mt-[20px] hover:bg-buttonColorHover bg-buttonColor font-opensans"
              >
                send request
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="bg-black text-center uppercase mt-1 text-[15px] text-white py-6">
        <p>14 SCOTTS ROAD, FAR EAST PLAZA, #03-03, SINGAPORE 228213</p>
      </div>
    </>
  );
};

export default Footer;
