import React from "react";
import Title from "../Line";
import { useMediaQuery } from "@material-ui/core";
import "./style.css";
import { getStaticURL } from "../../constants";
const AdvanceEquipment = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const title = "Advance Equipment";
  return (
    <div className="pt-5 md:pt-10">
      <Title title={title} />
      <div>
        <div className="text-left md:text-[18px] px-4 text-[16px] container mx-auto pb-5">
          <div className="flex">
            <p className="font-bold text-mainHeader">
              FTIR Spectroscopy (Fourier Transform Infrared)
            </p>
          </div>
          <p className="pb-5 text-mainText">
            FTIR spectrometers are used to measure absorption in materials
            within the infrared part of the electromagnetic spectrum. In
            infrared spectroscopy, IR radiation is passed through a sample. Some
            of the infrared radiation is absorbed by the sample and some of it
            is transmitted. The resulting spectrum represents the molecular
            absorption and transmission, creating a molecular fingerprint of the
            sample. Like a fingerprint, no two unique molecular structures
            produce the same infrared spectrum.
          </p>
          <p className="text-mainText">
            This makes infrared spectrometry very useful for several types of
            analysis. For example: Detect polymers, oils, and resins used for
            impregnation, e.g., in jadeite, opal, or emerald Distinguish certain
            natural and synthetic gem materials, e.g., emerald Opinions on heat
            treatment on ruby and sapphire
          </p>
        </div>
        <div className="text-left container mx-auto pb-5 md:pb-7 px-4 md:text-[18px] text-[16px]">
          <div className="flex">
            <p className="font-bold text-mainHeader">Raman Spectroscopy</p>
          </div>
          <p className="text-mainText">
            Raman spectroscopy is used to study vibrational, rotational, and
            other low-frequency modes in gemstones and minerals. It relies on
            inelastic scattering, or Raman (Raman effect) scattering of
            monochromatic light, from a laser in the visible, near-infrared, or
            near- ultraviolet range. The laser light interacts with molecular
            vibrations, phonons, or other excitations in the system, resulting
            in the laser photons' energy being shifted up or down. The energy
            shift gives information about the vibrational modes in the system.
            Raman spectrometry is a swift and reliable tool for the
            identification of minerals and gemstones. Raman spectrometry is used
            to:
          </p>
          <p className="text-mainText">
            Identify gem materials or individual parts of a gem or ornamental
            object. Identify inclusions Identify filling substances, e.g.,
            resins and oils in emerald
          </p>
        </div>

        <div className="lg:flex text-mainText">
          {isMobile ? (
            <div className="justify-center items-center bg-backgroundGray w-full px-4 md:px-2 pb-5">
              <img
                src={`${getStaticURL()}/assets/images/64.jpg`}
                className="object-cover advance-equipment"
                alt="clock"
              />
            </div>
          ) : (
            <div className="pr-4 md:pb-32 w-6/12 bg-backgroundGray">
              <img
                src={`${getStaticURL()}/assets/images/64.jpg`}
                className="h-[114%] mb-8 object-cover"
                alt="clock"
              />
            </div>
          )}
          <div className="flex text-mainText lg:w-6/12 bg-backgroundGray">
            <div className="px-4 text-left mb-5 pt-4 md:text-[18px] text-[16px] lg:pr-[80px]">
              <div>
                <p className="font-bold text-mainHeader">
                  Master Gemologist - Walter Tan
                </p>
              </div>
              <p className="mb-5 text-mainText">
                With over 30 years of experience in the jewelry industry, Walter
                is a Gemology Consultant at Asia’s leading gemological
                laboratory, Singapore Gemologist Institute (SGI), managing a
                group of gemological experts and setting company direction as
                the leader in the gemological field.
              </p>
              <p className="mb-5 text-mainText">
                As a Registered Master Valuer since 2002, Walter is a graduate
                gemologist from Diamond High Council of Belgium HRD (Institute
                of Gemology) and Gemological Institute of America. With his
                valuable insights and expertise in the gemology industry, Walter
                holds various positions as a trainer for both individuals and
                corporations in the field of gemology.
              </p>
              <p className="mb-5 text-mainText">
                Prior to his current active positions, Walter was the Gemology
                Director at Appraisal House and SIGMA International Gemology
                Laboratory. He performed roles as a professional jewellery
                valuation and evaluation consultant, being appointed as an
                appraiser for Singapore judicial branches.
              </p>
              <p className="mb-5 text-mainText">
                Walter’s early contributions to the Gemology industry includes
                providing Jewelry Evaluation, Gem Identification and Diamond
                Report for customers during his time at Lee Hwa. Over the years,
                Walter implemented a new standard of a diamond selection system
                to improve efficiency when curating diamonds. With a new diamond
                screening system that Walter introduced, this increased
                efficiency and productivity during purchase. To increase
                consumers’ level of confidence, Walter developed a new company
                diamond plotted certificates based on his decades of experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceEquipment;
