import React from "react";
const Line = (props) => {
  return (
    <div className="justify-center mx-2 items-center">
      <h2 className="pb-5 capitalize whitespace-nowrap text-mainHeader font-merryweather ml-5 mr-5 md:text-[36px] text-[18px]">
        {props.title}
      </h2>
      <div className="justify-center flex w-full lg:pb-10 pb-6">
        <div className="w-2/12 md:w-1/12 relative before:absolute before:bg-lineColor bg-lineColor h-[2px] right-0 before:w-full before:h-[2px] before:right-0 before:top-1/2"></div>
      </div>
    </div>
  );
};

export default Line;
